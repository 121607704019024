.modal {
  .close {
    outline: none;
    padding: 1.2rem;
    color: $black70;
    opacity: 1;

    .fa-times {
      -webkit-text-stroke: 2px white;
    }
  }

  &-body h3 {
    font-weight: 600;
    color: $black50;
  }
}

.dropdown {
  .dropdown-menu {
    border: 0;

    &:before {
      bottom: 100%;
      right: 1.25rem;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $dropdown-bg;
      border-width: 0.5rem;
      margin-left: -0.5rem;
      display: none;
    }

    .dropdown-item {
      &:focus {
        outline: none;
      }
    }
  }

  &.show {
    .dropdown-toggle {
      box-shadow: none;
    }

    .dropdown-menu:before {
      display: block;
    }
  }
}

.swal2-popup {
  padding: 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background: 0;

  .swal2-header,
  .swal2-content,
  .swal2-footer {
    background-color: white;
  }

  .swal2-actions {
    justify-content: flex-end;
  }

  .swal2-actions button {
    margin-right: 0;
    margin-left: 10px;
    min-width: 4rem;
  }

  .swal2-content {
    padding: 0 2rem 2rem;
  }

  .swal2-header {
    padding: 1rem 2rem;
  }

  .swal2-title {
    color: $black70;
  }

  .swal2-title {
    color: $black50;
  }
}

#toast-container > div {
  width: 420px;
}

#toast-container {
  > div {
    width: 420px;
  }

  .toast:before {
    font-size: 25px;
    margin-left: -1rem;
    padding-right: 1.5rem;
  }

  div.toast-close-button {
    line-height: 1.3;
    position: absolute;
    top: 0;
    right: 0;

    > button {
      font-weight: normal;
      font-size: 2rem;
      top: 0;
      right: 0;
      padding: 0rem 0.75rem;
      text-shadow: none;
      outline: none;

      &:hover {
        opacity: 1;
        color: white;
      }
    }
  }
}
