@import '~tippy.js/dist/tippy.css';

.tippy-box {
  background-color: $dropdown-bg;
  border-radius: 1px;
}

.tippy-arrow {
  color: $dropdown-bg;
}
