.modal-content {
  border: none;
  border-radius: none;
  @include themify($themes) {
    background-color: themed('backgroundColor');
  }
}

.modal-dialog {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  width: $modal-md;
}

.modal-sm {
  width: $modal-sm;
}

.modal-lg {
  width: $modal-lg;
}

.modal-header {
  @include themify($themes) {
    background-color: themed('foregroundColor');
    border-bottom: 2px solid #dee2e6;
    color: themed('textColor');
  }
}

.modal-body {
  @include themify($themes) {
    background-color: themed('foregroundColor');
    color: themed('textColor');
  }

  h3,
  .section-header > * {
    font-weight: normal;
    text-transform: uppercase;
    @include themify($themes) {
      color: themed('textMuted');
    }
  }
}
.modal .list-group-flush {
  :first-child {
    border-top: none;
  }

  :last-child {
    border-bottom: none;
  }
}

.modal .list-group-flush-2fa {
  div {
    border-left: none;
    border-right: none;
  }
  div:first-child {
    border-top: none;
  }

  div:last-child {
    border-bottom: none;
  }
}

.modal-footer {
  justify-content: flex-start;
  @include themify($themes) {
    background-color: themed('footerBackgroundColor');
    border-top: 2px solid #dee2e6;
  }
}

.close {
  @include themify($themes) {
    color: themed('textColor');
  }
}

#totpImage {
  @include themify($themes) {
    filter: themed('imgFilter');
  }
}

.totp {
  .totp-code {
    font-size: 1.2rem;
    font-weight: 600;
    color: $black70;
  }

  .totp-countdown {
    display: block;
    margin: 3px 3px 0 0;
    user-select: none;

    .totp-sec {
      font-size: 0.75rem;
      position: absolute;
      text-align: center;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $primary;
    }

    svg {
      width: 48px;
      height: 48px;
      transform: rotate(-90deg);
    }

    .totp-circle {
      fill: none;
      @include themify($themes) {
        stroke: themed('primary');
      }

      &.inner {
        stroke-dasharray: 78.6;
        stroke-dashoffset: 0;
        stroke-width: 3;
      }
    }
  }

  &.low {
    .totp-sec,
    .totp-code {
      @include themify($themes) {
        color: themed('danger');
      }
    }

    .totp-circle {
      @include themify($themes) {
        stroke: themed('danger');
      }
    }
  }
}

.cdk-drag-preview {
  border-radius: $border-radius;
  opacity: 0.8;
  z-index: $zindex-tooltip !important ;
  @include themify($themes) {
    background: themed('cdkDraggingBackground');
  }
}
