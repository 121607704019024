.form-control {
  font-size: 1rem;

  ~ .invalid-feedback {
    display: none;
  }

  &.ng-invalid.ng-touched {
    border-color: $danger;
    animation: shake 0.2s;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($secondary, 0.25);
    }

    ~ .invalid-feedback {
      display: block;

      + .form-text {
        display: none;
      }
    }
  }

  &:disabled,
  &[readonly] {
    &,
    &:focus {
      cursor: default;
      box-shadow: none;
      border-color: $black15;
      background-color: $black15;
    }
  }

  &.-filled {
    background-color: $black5;
    border-color: $black5;

    &:focus {
      box-shadow: none;
      border-color: $black5;
    }

    &::placeholder {
      color: darken($black15, 10%);
    }
  }
}

label[for] {
  color: $black50;
}

.icon-in-input {
  position: absolute;
  right: 0rem;
  padding: 0.75rem;

  + .icon-in-input {
    right: 3rem;
  }
}

.button-in-input {
  position: absolute;
  right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  + .button-in-input {
    right: 2.7rem;
  }
}

.invalid-feedback {
  display: block;
  animation: show-down 0.2s $easeOutExpo;
  font-weight: 600;
}

.form-check {
  display: flex;
  align-items: center;
  padding-left: 0;

  .form-check-label {
    color: $black70;

    > small {
      color: $black50;
      font-weight: 500;
    }
  }

  > .a-help {
    margin-left: 0.5rem;
  }

  .form-check-input {
    position: relative;
    margin: 0 0.5rem 0 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: 5px !important;
  }

  &.-check-on-top {
    align-items: flex-start;

    .form-check-input {
      margin-top: 0.3rem;
      width: 1rem;
      height: 1rem;
    }
  }
}

input[type='checkbox'] {
  appearance: none;
  border: 2px solid $black50;
  transition: box-shadow 0.2s $easeOutExpo;
  border-radius: $border-radius;
  position: relative;

  &:before {
    font-family: FontAwesome;
    font-size: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 0.8rem;
    color: $primary;
    transform: translate(-50%, -50%);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:checked {
    border-color: $primary;

    &:before {
      content: '\f00C';
      display: block;
    }
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba($primary, 0.25);
  }
}
