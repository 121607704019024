:root {
  --color-background: #ecf0f5;
  --color-background-alt: #fbfbfb;
  --color-background-alt2: #1d2769;

  --color-primary-300: #6795e8;
  --color-primary-500: #1d2769;
  --color-primary-700: #1252a3;

  --color-secondary-100: #f0f0f0;
  --color-secondary-300: #ced4dc;
  --color-secondary-500: #89929f;
  --color-secondary-700: #212529;

  --color-success-500: #017e45;
  --color-success-700: #00552e;

  --color-danger-500: #c83522;
  --color-danger-700: #98291b;

  --color-warning-500: #8b6609;
  --color-warning-700: #694d05;

  --color-info-500: #555555;
  --color-info-700: #3b3a3a;

  --color-text-main: #212529;
  --color-text-muted: #6d757e;
  --color-text-contrast: #ffffff;
  --color-text-alt2: #ffffff;

  --tw-ring-offset-color: #fff;
}

.theme_light {
  /* should be left empty as white is the default */
}

.theme_dark {
  --color-background: #1f242e;
  --color-background-alt: #161c26;
  --color-background-alt2: #2f343d;

  --color-primary-300: #1d2769;
  --color-primary-500: #6a99f0;
  --color-primary-700: #b4ccf9;

  --color-secondary-100: #2f343d;
  --color-secondary-300: #6e7689;
  --color-secondary-500: #bac0ce;
  --color-secondary-700: #ffffff;

  --color-success-500: #52e07c;
  --color-success-700: #a8efbe;

  --color-danger-500: #ff8d85;
  --color-danger-700: #ffbfbb;

  --color-warning-500: #ffeb66;
  --color-warning-700: #fff5b3;

  --color-info-500: #a4b0c6;
  --color-info-700: #d1d7e2;

  --color-text-main: #ffffff;
  --color-text-muted: #bac0ce;
  --color-text-contrast: #191e26;
  --color-text-alt2: #ffffff;

  --tw-ring-offset-color: #1f242e;
}
