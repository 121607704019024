.card-ul {
  list-style: none;
  padding-left: 0;

  > li {
    position: relative;
  }

  > li.active > a {
    font-weight: bold;
    color: theme-color('primary');
  }

  > li > a {
    padding: 0.3rem 0;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;

      > span {
        text-decoration: underline;
      }
    }

    .badge {
      line-height: 1.5;
    }

    > span > .badge {
      position: absolute;
      left: 100%;
    }

    > .fa {
      margin-left: 0;
      margin-right: 0.5em;
    }
  }

  .li-control {
    position: absolute;
    right: 0;
    top: 0;
    display: block;
  }
}

.card-ul-divider {
  border-bottom: 2px solid $black15;
  margin: 0 $page-col-padding * -1 1rem;
}

.card[class*=' border-'] {
  .card-header {
    border-bottom: 0;
  }
}

.card.-flat {
  background: 0;
  border: 0;

  .card-header {
    background: 0;
    font-size: $medium-font-size;
    font-weight: 900;
    color: $black50;
    border-bottom: 0;
    padding: $page-col-padding $page-col-padding 0;

    + .list-group {
      margin-top: 1rem;
    }
  }
}

.page-sub-content {
  padding-left: $page-col-padding !important;
  padding-right: $page-col-padding !important;
  padding-top: $page-col-padding;
  margin-bottom: 4rem;
}

.page-left,
.page-right {
  display: flex;
  flex-direction: column;

  .card {
    &:last-child {
      flex-grow: 1;
    }

    h3 {
      color: $black70;
      text-transform: uppercase;
    }

    .list-group {
      .list-group-item {
        background: 0;

        &.active {
          border-left: 0;
          background-color: $black15;
          margin-left: 0;
          margin-right: 0;
          text-decoration: none;
        }
      }
    }

    .card-ul > li {
      > a:first-child {
        color: $black70;
        margin-left: -$page-col-padding;
        margin-right: -$page-col-padding;
        padding-left: $page-col-padding;
        padding-right: $page-col-padding;
        padding-top: 0.4em;
        padding-bottom: 0.4em;

        &:hover {
          color: $black70;
        }
      }

      &.active > a {
        color: darken($black70, 10%);
        background-color: $black15;
        font-weight: 500;

        span {
          text-decoration: none;
        }
      }
    }

    &-body {
      padding-left: $page-col-padding;
      padding-right: $page-col-padding;
    }
  }
}

.page-left {
  .card {
    border-right: 2px solid $black15;
  }
}

.page-middle {
  padding-left: $page-col-padding - 1;
  padding-right: $page-col-padding - 1;
}

.page-right {
  .card {
    border-left: 2px solid $black15;
  }
}

.callout {
  border: 0;
  background: 0;
  padding: 1rem;
  color: $black70;
  font-weight: 600;
  background-color: $black5;

  p:last-child {
    margin: 0;
  }

  h3 {
    font-weight: 500;
  }

  &-warning h3 {
    color: theme-color('warning');
  }

  &-info h3 {
    color: theme-color('info');
  }

  &-danger h3 {
    color: theme-color('danger');
  }

  &-success h3 {
    color: theme-color('success');
  }
}

.card.bg-secondary .btn[class*=' btn-outline-']:hover {
  color: theme-color(secondary);
}
