.navbar {
  padding-left: 0;
  padding-right: 0;
  @include themify($themes) {
    background-color: themed('navBackground');
  }

  &.nav-background-alt {
    @include themify($themes) {
      background-color: themed('navBackgroundAlt');
    }
  }

  .nav-item {
    > .nav-link {
      @include themify($themes) {
        font-weight: themed('navWeight');
      }
    }
    &.active > .nav-link {
      @include themify($themes) {
        font-weight: themed('navActiveWeight');
      }
    }
  }
}

.navbar-brand {
  margin-bottom: -20px;
  margin-top: -20px;
  font-size: 1rem;
}

.nav-tabs .nav-link.active {
  @include themify($themes) {
    background: themed('navActiveBackground');
    border-color: themed('borderColor');
  }
}

.org-nav {
  height: 100px;
  min-height: 100px;
  @include themify($themes) {
    background-color: themed('navOrgBackgroundColor');
    border-bottom: 1px solid themed('borderColor');
    color: themed('textColor');
  }

  .container {
    height: 100%;
  }

  .org-name {
    line-height: 1;
    text-align: left;
    font-weight: normal;
    span {
      display: block;
      font-size: $font-size-lg;
      @include themify($themes) {
        color: themed('textHeadingColor');
      }
    }
  }
}

.tabbed-nav {
  @include themify($themes) {
    border-bottom: 1px solid themed('borderColor');
    color: themed('textColor');
  }
}

.org-nav,
.tabbed-nav {
  .nav-tabs {
    border-bottom: none;

    a {
      &:not(.active) {
        border-color: transparent;
        @include themify($themes) {
          color: themed('textColor');
        }
      }

      &.active {
        font-weight: bold;
        padding-top: calc(#{$nav-link-padding-y} - 2px);
        @include themify($themes) {
          border-top: 3px solid themed('primary');
          border-bottom: 1px solid themed('backgroundColor');
          color: themed('linkColor');
        }
      }

      &.disabled {
        @include themify($themes) {
          color: themed('inputDisabledColor');
        }
      }
    }
  }
}
