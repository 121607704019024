.table.table-list {
  td.table-list-checkbox {
    vertical-align: middle;
    padding-top: 0.5rem;
  }

  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    vertical-align: middle;
  }

  td.table-list-options .dropdown-menu {
    margin-top: 0.5rem;

    &:before {
      right: 0.75rem;
    }
  }
}
