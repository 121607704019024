html,
body {
  height: 100%;
}

body {
  toaster-container + router-outlet + * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  app-navbar + router-outlet + *,
  .org-nav + router-outlet + *,
  toaster-container + router-outlet + * {
    flex-grow: 1;
    display: flex;
  }
}

.page-content {
  margin-top: 0;
  margin-bottom: 0;

  > .row {
    height: 100%;
  }
}

.page-section,
.page-content {
  &.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.page-header {
  border: 0;
  align-items: flex-start;
}

.search-wrapper {
  svg-icon {
    position: absolute;
    right: 0;
    width: 2.75rem;
    height: 2.75rem;
    padding: 0.9rem;

    svg {
      fill: $black70;
      vertical-align: top;
    }

    &.svg-icon-cross-su {
      cursor: pointer;
    }
  }

  + .card-ul {
    margin-top: $page-col-padding;
  }
}

app-status-filter > ul {
  margin-top: $page-col-padding !important;
}

app-status-filter > ul > li > a,
app-type-filter > ul > li > a,
app-collection-filter > ul > li > a,
{
  color: #333333 !important;
}

.page-header,
.secondary-header {
  border: 0;

  h1 {
    color: rgb(128, 128, 128) !important;
    font-size: $big-font-size;
    font-weight: 700;
  }
}

app-vault-bulk-actions {
  display: flex;
}

.a-help {
  color: inherit;
  font-size: 115%;
  line-height: 1;

  &:hover {
    color: $black70;
  }
}

.lead {
  font-size: 1rem;
  font-weight: 500;

  &:first-child {
    font-size: 1.5rem;
    color: $black70;
  }
}

a > i.fa:before {
  margin-left: 5px;
}
