@import './variables';

.btn .fa {
  font-size: 125% !important;
  position: relative !important;
  top: 2px !important;
}

.btn-outline-secondary {
  color: $secondary !important;

  &:hover:not(:disabled) {
    color: white !important;
  }
}

.fa-plus.fa-plus-thin,
.btn-outline-primary .fa-plus {
  transition: -webkit-text-stroke 0.15s ease-in-out !important;
  -webkit-text-stroke: 2px white !important;
}

.btn-outline-primary {
  &:hover .fa-plus,
  &:focus:active .fa-plus {
    -webkit-text-stroke: 2px $primary !important;
  }
}

.btn-link {
  font-weight: 600 !important;
}

.dropdown {
  .dropdown-toggle {
    cursor: pointer !important;

    &:after {
      @include fa-icon;
      content: $fa-var-angle-down;
      border: 0 !important;
      margin: 0 0 0 0.2em !important;
      font-weight: 600 !important;
      transition: transform 0.2s $easeOutExpo !important;
      vertical-align: initial !important;
    }
  }

  &.show {
    .dropdown-toggle:after {
      transform: rotateZ(180deg) !important;
    }
  }
}

.btn[class*=' btn-outline-'] {
  /* .badge {
    background-color: currentColor  !important;
  } */

  &[data-content] {
    content: attr(data-content) !important;
    color: white !important;
  }

  &:hover,
  &:active,
  &.active {
    .badge {
      color: white !important;
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
}
