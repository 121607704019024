.org-header {
  padding: 2rem 15%;
}

.org-nav {
  height: auto;
  border: 0;
  flex-shrink: 0;
  background: 0;
  border-bottom: 2px solid $border-color;

  + router-outlet + * {
    background-color: $black5;

    .fa-plus.fa-plus-thin,
    .btn-outline-primary .fa-plus {
      -webkit-text-stroke: 2px $black5;
    }
  }

  .nav-tabs {
    margin-left: 15%;

    .nav-item {
      margin-bottom: -2px;
      min-width: 7rem;

      text-align: center;

      .nav-link {
        border: 0;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;

        > i {
          margin-right: 0.5rem;
        }

        &:hover {
          color: darken($black50, 10%);
        }

        &.active {
          background-color: $black5;
          border-color: $black15;
          border-bottom: none;
        }
      }
    }
  }

  .org-name {
    > span {
      font-size: 1.71428571rem; // 36 pt
      font-weight: 700;
    }
  }
}

app-organization-layout {
  .search-wrapper .form-control {
    background-color: white;
  }

  .page-left .card:first-child {
    margin-top: $page-col-padding;

    .card-header {
      padding-top: 0;
    }
  }

  .page-left .card:last-child {
    margin-bottom: $page-col-padding;
    border-top: none;
  }

  .callout {
    background-color: transparent;
    border: 2px solid $black15;
  }
}
