$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffb500;
$green: rgb(40, 167, 69);
$teal: #20c997;
$cyan: #56b7e6;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #1d2769;
$secondary: #e6332a;
$success: #28a745;
$info: #56b7e6;
$warning: #ffb500;
$danger: $secondary;
$light: #f8f9fa;
$dark: #343a40;
$black15: rgb(217, 217, 217);
$black50: rgb(128, 128, 128);
$black5: rgb(242, 242, 242);
$black70: rgb(77, 77, 77);

$body-color: $black50;
$table-color: $black70;
$input-plaintext-color: $black70;
$popover-body-color: $black70;
$card-border-color: $black15;
$list-group-action-active-color: $black70;
$list-group-color: $black70;
$list-group-border-color: $black15;
$list-group-active-border-color: $black15;
$input-border-color: $black15;
$border-width: 2px;
$input-placeholder-color: $black15;
$code-color: $secondary;
$font-weight-normal: 500;
$dropdown-color: white;
$dropdown-bg: $black50;
$dropdown-link-color: white;
$dropdown-link-hover-color: white;
$dropdown-link-hover-bg: darken($black50, 10%);
$dropdown-divider-bg: darken($black50, 5%);
$dropdown-link-active-color: white;
$dropdown-link-active-bg: $black70;
$dropdown-box-shadow: none;

$font-size-root: 16px; // 21
$font-size-base: 1rem; // 21
$header-height: 6rem;
$page-col-padding: 1.9047619rem;
$header-margin: 3rem;
$header-font-size: 1rem;
$big-font-size: 1.42857143rem;
$medium-font-size: 1rem;
$small-font-size: 0.875rem;

$border-radius: 1px;
$border-radius-lg: 1px;
$border-radius-sm: 1px;
$input-btn-font-size: $small-font-size;
$input-btn-padding-y: 0.55rem;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-y-lg: 0.5rem;
$btn-font-size: $medium-font-size;
$btn-padding-y: $input-btn-padding-y - ($medium-font-size - $small-font-size);
$btn-padding-y-lg: $input-btn-padding-y-lg - ($medium-font-size - $small-font-size);
$btn-padding-y-sm: $input-btn-padding-y-sm - ($medium-font-size - $small-font-size);
$list-group-item-padding-y: 0.8rem;
$list-group-item-padding-x: $page-col-padding;

$font-family-sans-serif: 'Barlow', 'Open Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$theme-colors: (
  'primary-accent': $primary,
  'dark': $black70,
);

$easeOutExpo: cubic-bezier(0.005, 1, 0.22, 1);
$easeInExpo: cubic-bezier(0.7, 0, 0.84, 0);
$easeInOutExpo: cubic-bezier(0.87, 0, 0.13, 1);
$easeElastic: cubic-bezier(0, 1.4, 1, 1);
$easeInBack: cubic-bezier(0.36, 0, 0.66, -0.56);
