html {
  font-size: 14px;
}

body {
  min-width: 1010px;

  &.layout_frontend {
    @media (prefers-color-scheme: dark) {
      background-color: $darkDarkBlue2;
    }
    @media (prefers-color-scheme: light) {
      background-color: $white;
    }
    @include themify($themes) {
      background-color: themed('layoutFrontendColor');
      color: themed('textHeadingColor');
    }
  }

  @include themify($themes) {
    background-color: themed('backgroundColor');
    color: themed('textColor');
  }

  &.full-width:not(.layout_frontend) {
    .container {
      min-width: 980px;
      width: 90%;
    }
  }
}

.container {
  margin: 0 auto;
  max-width: none;
  padding: 0;
  width: 980px;
}

.page-header,
.secondary-header,
.tabbed-header {
  margin-bottom: 0.5rem;
  padding-bottom: 0.6rem;

  &:not(.text-danger) {
    h1,
    h2,
    h3,
    h4 {
      margin: 0;
      @include themify($themes) {
        color: themed('textHeadingColor');
      }
    }
  }
}

.page-header,
.secondary-header {
  @include themify($themes) {
    border-bottom: 2px solid #dee2e6;
  }
}

.secondary-header,
.spaced-header {
  margin-top: 4rem;
}

.tabbed-header {
  margin-top: 1.5rem;
}

img.logo {
  width: 250px;
  display: block;
  margin: 0 auto;

  &.logo-themed {
    @include themify($themes) {
      content: url('../images/logo-' + themed('logoSuffix') + '@2x.png');
    }
  }
}

.page-content {
  margin-top: 20px !important;
}

.footer {
  padding: 2rem 0 2rem 0;
}

hr,
.dropdown-divider {
  @include themify($themes) {
    border-top: 1px solid themed('separatorHr');
  }
}

.min-height-fix {
  min-height: 1px;
}

.overflow-hidden {
  overflow: hidden;
}

.cursor-move {
  cursor: move;
}

h1,
h2,
h3,
h4,
h5 {
  @include themify($themes) {
    color: themed('textHeadingColor');
  }

  small {
    font-size: 80%;
  }

  &.spaced-header {
    @include themify($themes) {
      color: themed('textHeadingColor');
    }
  }
}

a {
  @include themify($themes) {
    color: themed('linkColor');
  }

  &.text-body {
    @include themify($themes) {
      color: themed('textHeadingColor');
      font-weight: themed('linkWeight');
    }
  }
}

code {
  @include themify($themes) {
    color: themed('codeColor');
  }
}

.bwi-icon-above-input {
  height: 1.5em;
}

.text-lg {
  font-size: $font-size-lg;
}

.text-strike {
  text-decoration: line-through;
}

.font-weight-semibold {
  font-weight: 600;
}

.btn:focus,
.swal2-popup .swal2-actions button:focus,
.btn.focus,
.swal2-popup .swal2-actions button.focus,
.form-control:focus {
  @include themify($themes) {
    box-shadow: 0 0 0 0.2rem themed('focus');
  }
}

/* Override Bootstrap theming */

.bg-primary {
  @include themify($themes) {
    background-color: themed('bgPrimaryColor');
  }
}

.bg-light {
  @include themify($themes) {
    background-color: themed('bgLightColor');
  }
}

.bg-success {
  @include themify($themes) {
    background-color: themed('success');
    color: themed('textSuccessColor');
  }
}

.bg-warning {
  @include themify($themes) {
    background-color: themed('warning');
    color: themed('textWarningColor');
  }
}

.bg-error,
.bg-danger {
  @include themify($themes) {
    background-color: themed('danger');
    color: themed('textDangerColor');
  }
}

.bg-info {
  @include themify($themes) {
    background-color: themed('info');
    color: themed('textInfoColor');
  }
}

.border-primary {
  @include themify($themes) {
    border-color: themed('borderPrimaryColor');
  }
}

.border-warning {
  @include themify($themes) {
    border-color: themed('warning');
  }
}

.border-danger {
  @include themify($themes) {
    border-color: themed('danger');
  }
}

.border-info {
  @include themify($themes) {
    border-color: themed('info');
  }
}

.text-success {
  @include themify($themes) {
    color: themed('success');
  }

  & > h1,
  h2,
  h3,
  h4 {
    @include themify($themes) {
      color: themed('success');
    }
  }
}

.text-warning {
  @include themify($themes) {
    color: themed('warning');
  }

  & > h1,
  h2,
  h3,
  h4 {
    @include themify($themes) {
      color: themed('warning');
    }
  }
}

.text-danger {
  &:not(.dropdown-item) {
    @include themify($themes) {
      color: themed('danger');
    }

    & > h1,
    h2,
    h3,
    h4 {
      @include themify($themes) {
        color: themed('danger');
      }
    }
  }
}

.text-muted {
  @include themify($themes) {
    color: themed('textMuted');
  }
}

button i.bwi {
  margin-right: 0.25rem;
}
