img.logo {
  width: 250px;
}

html {
  font-size: $font-size-root;

  &.loading {
    perspective: 400px;
    height: 100%;

    body {
      height: 100%;
    }

    app-root {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .fa-shield {
      animation: fa-rotate-y 1s linear infinite;
    }

    .logo {
      animation: show-scale 0.5s $easeElastic;
    }
  }
}

@keyframes fa-rotate-y {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(359deg);
  }
}

svg-icon {
  svg {
    vertical-align: baseline;
  }

  &.-portrait {
    svg {
      height: 100%;
      width: auto;
      vertical-align: top;
    }
  }
}

.favicon {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
}

p,
h2 {
  color: $black70;
}

.border-0 {
  border: 0;
}

.p-relative {
  position: relative;
}

.page-left .card .card-ul > li.active > a,
.page-right .card .card-ul > li.active > a {
  color: #343434;
  background-color: #d9d9d9;
  font-weight: 500;
}

app-user-layout {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@import './container.scss';
@import './animations.scss';
@import './button.scss';
@import './input.scss';
@import './card.scss';
@import './modal.scss';
@import './navbar.scss';
@import './tooltip.scss';
@import './table.scss';
@import './org.scss';
