.navbar {
  height: $header-height;
  padding-top: 0;
  padding-bottom: 0;

  > .container-fluid {
    padding-left: $header-margin;
    padding-right: $header-margin;
  }

  &-brand {
    img {
      height: 50px;
      width: auto;
    }
  }

  &-nav .nav-item.-featured {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: $header-height;
    margin-left: 1rem;

    &.active {
      position: relative;
      z-index: 1;

      &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-bottom-color: white;
        border-width: 1rem;
        margin-left: -1rem;
      }
    }

    .nav-link {
      text-transform: uppercase;
      font-size: $medium-font-size;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 0.8rem;
      padding-left: 0.8rem;
      padding-bottom: 1rem;

      svg-icon {
        margin-top: 0.25rem;
        width: 24px;
        height: 24px;

        svg {
          fill: rgba(255, 255, 255, 0.7);
        }
      }

      &:hover svg {
        fill: white;
      }
    }
  }

  &-divider {
    width: 2px;
    border-radius: 1px;
    background-color: rgba(white, 0.7);
    margin: 2rem 2rem;
    align-self: stretch;
  }
}

#nav-profile {
  .svg-icon-user-su {
    font-size: 2rem !important;

    svg {
      vertical-align: baseline;
    }
  }

  &:after {
    display: none;
  }
}
